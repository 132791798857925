import { post, deletes, get, downFile } from 'common/request'
export default {
  /**
   * 获取
   */

  // 获取志愿者管理列表数据
  volunteerInfoList: params => get('/volunteerInfo/list', params),
  // 获取志愿者团队列表数据
  volunteerGroupList: params => get('/volunteerGroup/list', params),
  // 根据id获取志愿者团队信息详情
  getVolunteerGroupById: id => get(`/volunteerGroup/getById?id=${id}`),
  // 根据id获取居民信息详情
  getResidentArchivesById: id => get(`/residentArchives/getById?id=${id}`),
  // 根据志愿者团队id获取志愿者成员列表数据
  getVolunteerGroupDetail: params => get(`/volunteerGroupDetail/queryVolunteers`,params),
    // 根据id获取志愿者信息详情
    getVolunteerInfoById: id => get(`/volunteerInfo/getById?id=${id}`),
  //获取网格树
  getTreeList: args => get('/scGridManagement/getTreeList', args),
  // // 获取省的行政区划
  // getRegionInfo: args => get(`/regionInfo/list?level=1`),
  // // 获取市级的行政区划
  // getProvinceRegionInfo: provinceCode => get(`/regionInfo/list?level=2&&provinceCode=${provinceCode}`),
  // // 获取区级的行政区划
  // getCityRegionInfo: cityCode => get(`/regionInfo/list?level=3&&cityCode=${cityCode}`),
  // // 获取县级的行政区划
  // getCountyRegionInfo: countyCode => get(`/regionInfo/list?level=4&&countyCode=${countyCode}`),
  // // 获取所有小区
  getGridManagement: args => get('/scGridManagement/queryAll', args),

  /**
   * 插入/修改
   */
  // 新增志愿者团队
  addVolunteerGroup: args => post('/volunteerGroup/add', args),
  // 根据id审核志愿者团队
  auditVolunteerGroup: args => post('/volunteerGroup/audit', args),
  // 导出
  exports: args => downFile('/residentArchives/exportXls', args),
  // 根据id删除
  deleteVolunteerGroupById: id => deletes(`/volunteerGroup/deleteById?id=${id}`),
  // 批量删除
  deleteVolunteerGroupByIds: ids => deletes(`/volunteerGroup/ids?ids=${ids}`),
  // 根据id编辑志愿者团队信息
  updateVolunteerGroup: args => post('/volunteerGroup/update', args),
  // 删除图片
  del: args => get(`/file/del/?id=${args}`),

  /**
     * 字典
     */
  // form里面的下拉获取数据字典
  dictData: args => get('/dict-data/getByDicKind', args),
}
